<template>
    <div>
        <CSDialog
            :dialogTitle="itemId && itemId !== '' ?'修改考核项' : '添加考核项'"
            dialogWidth="580px" :is-submitting="isSubmitting"
            :dialogVisible="addassessmentItem"
            @onClose="addassessmentItem = false"
            @onConfirm="affirm"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px; font-size: 24px;"
            >
                <div style="margin-bottom: 13px;">
                    <label style="width: 80px; margin-right: 20px; text-align: right;">考核项</label>
                    <input
                        maxlength="400"
                        style="width: 400px; border: 1px solid #999; border-radius: 4px; padding-left: 10px;"
                        v-model="content"
                        placeholder="请输入"
                    />
                </div>
                <div>
                    <label style="width: 80px; margin-right: 20px; text-align: right;">分数</label>
                    <CSSelect
                        height="40px"
                        style="width: 400px"
                        iWidth="36px"
                    >
                        <select v-model="score" style="padding-left: 6px;color: #999999">
                            <option value="">请选择</option>
                            <option v-for="n in scoreOptions" :key="n" :value="n">
                                {{ n }}
                            </option>
                        </select>
                    </CSSelect>
                </div>
                <template
                    v-if="
                        assessmentItemType === 2 &&
                        [1001, 1002].includes(department)
                    "
                >
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">部门</label>
                        <div class="col-sm-8 row">
                            <div class="col-sm-8">
                                <CSSelect
                                    height="40px"
                                    style="width: 400px"
                                >
                                    <select v-model="checkedOrg">
                                        <option value="" disabled>
                                            请选择
                                        </option>
                                        <option
                                            v-for="department in orgList"
                                            :key="department.code"
                                            :value="department"
                                        >
                                            {{ department.name }}
                                        </option>
                                    </select>
                                </CSSelect>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </CSDialog>
    </div>
</template>


<script>
import {
    addGeneralItemUrl,
    editGeneralItemUrl,
    addProfessionalItem,
    editProfessionalItem,
} from "@/requestUrl";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";

// 考核项目
const ASSESSMENT_ITEM_TYPE = {
    COMMON_ITEM: 1,
    BUSINESS_ITEM: 2,
};


export default {
    props: {
        orgList: Array,
        target: Number,
        assessmentItemType: Number,
        department: [String, Number],
    },
    components: {
        CSSelect,
        CSDialog,
    },
    data() {
        return {
            scoreOptions: [],
            itemId: null,
            orgId: "",
            formId: null,
            dept: "",
            isSubmitting: false,
            content: "",
            score: "",
            checkedOrg: null,
            addassessmentItem: false,
        };
    },
    mounted(){
        for (let i = 0.5; i<= 20; i+=0.5) {
            this.scoreOptions.push(i);
        }
    },
    methods: {
        affirm() {
            if (
                ASSESSMENT_ITEM_TYPE.BUSINESS_ITEM === this.assessmentItemType
            ) {
                this.editBusinessAssessmentItem();
            }
            if (ASSESSMENT_ITEM_TYPE.COMMON_ITEM === this.assessmentItemType) {
                this.editCommonAssessmentItem();
            }
        },
        editCommonAssessmentItem() {
            let params = {
                formId: this.formId,
                itemId: this.itemId,
                content: this.content,
                score: this.score,
                operatorId: this.$vc.getCurrentStaffInfo().id,
            };
            if (this.itemId && this.itemId !== "") {
                params.itemId = this.itemId;
            }
            this.isSubmitting = true;
            this.$fly
                .post(
                    this.itemId && this.itemId !== ""
                        ? editGeneralItemUrl
                        : addGeneralItemUrl,
                    params
                )
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.changeShowStatus(false);
                    this.$vc.emit(this.$route.path, "assessmentManage", "list", null);
                    this.$vc.toast("保存成功");
                })
                .finally(() => this.isSubmitting = false);
        },
        editBusinessAssessmentItem() {
            if (this.checkedOrg) {
                this.orgId = this.checkedOrg.code;
                this.dept = this.checkedOrg.dutyType;
            }
            let params = {
                orgId: this.orgId,
                dept: this.dept,
                regionId: this.$vc.getCurrentRegion().communityId,
                content: this.content,
                score: this.score,
                target: this.target,
                operatorId: this.$vc.getCurrentStaffInfo().id,
            };
            if (this.itemId && this.itemId !== "") {
                params.itemId = this.itemId;

            }
            this.isSubmitting = true;
            this.$fly
                .post(
                    this.itemId && this.itemId !== ""
                        ? editProfessionalItem
                        : addProfessionalItem,
                    params
                )
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.changeShowStatus(false);
                    this.$vc.emit(this.$route.path, "assessmentManage", "list", null);
                    this.$vc.toast("保存成功");
                })
                .finally(() => this.isSubmitting = false);
        },
        initParams() {
            this.itemId = null;
            this.orgId = "";
            this.formId = null;
            this.dept = "";
            this.content = "";
            this.score = '';
            this.orgList.forEach((val) => {
                if (val.dutyType === this.department.toString()) {
                    this.checkedOrg = val;
                }
            });
        },
        /**
         * 显示隐藏窗口
         * @param {Boolean} isShow 显示/隐藏
         * @param {Object} itemInfo 考核项数据
         * */
        changeShowStatus(isShow, itemInfo) {
            this.initParams();
            if (isShow && itemInfo) {
                this.itemId = itemInfo?.id;
                for (let key in itemInfo) {
                    this[key] = itemInfo[key];
                }
                if (
                    itemInfo.orgId &&
                    itemInfo.dept &&
                    this.assessmentItemType ===
                        ASSESSMENT_ITEM_TYPE.BUSINESS_ITEM
                ) {
                    this.orgList.forEach((val) => {
                        if (val.orgId === itemInfo.orgId) {
                            this.checkedOrg = val;
                        }
                    });
                }
            }
            this.addassessmentItem = isShow;
        },
    },
};
</script>

<style lang="stylus" scoped>
.modal
    &-body
        .col-form-label
            width 80px
            font-size 24px
            margin-right 40px
            text-align right
            color #444
        .cs-input
            width 400px
            height 40px
            max-width 400px
            max-height 200px
            font-size 24px
            padding 0 5px
            box-sizing border-box
            outline none
            resize none
            // background #999
        select
            font-size 24px
    &-footer
        button
            font-size 24px
            width 80px
            height 50px
            &:not(:last-of-type)
                margin-right 30px
input::-webkit-input-placeholder
    color: #999;
input::-moz-placeholder
    color: #999;
input::-moz-placeholder
    color: #999;
input::-ms-input-placeholder
    color: #999;
</style>
